import './main.css';
import cameraIcon from './demo/camera.png';
import activeCameraIcon from './demo/camera_active.png';

import SVG from 'svg.js';
import 'pannellum/build/pannellum.css';
import 'pannellum/build/pannellum.js';

const config = {
  "default": {
    "firstScene": "R0010446-aquarium",
    "type": "cubemap",
    "hfov": 120,
    "sceneFadeDuration": 1000,
    "showControls": false,
    "autoLoad": true,
    "compass": true
  },

  "map": {
    outline: [
      [200, 570],
      [320, 570],
      [320, 380],
      [335, 380],
      [320, 380],
      [320, 300],
      [400, 300],
      [400, 175],
      [415, 175],
      [415, 160],
      [400, 160],
      [400, 80],
      [550, 80],
      [550, 160],
      [450, 160],
      [450, 175],
      [550, 175],
      [550, 380],
      [370, 380],
      [370, 500],
      [380, 500],
      [370, 500],
      [370, 380],
      [490, 380],
      [490, 500],
      [420, 500],
      [420, 630],
      [430, 640],
      [420, 630],
      [420, 500],
      [550, 500],
      [550, 680],
      [460, 680],
      [460, 660],
      [460, 680],
      [550, 680],
      [550, 780],
      [420, 780],
      [420, 680],
      [430, 680],
      [410, 680],
      [420, 680],
      [420, 780],
      [320, 780],
      [320, 680],
      [380, 680],
      [100, 680],
      [100, 600],
      [180, 600],
    ]
  },

  "scenes": {
    "R0010445-aquarium-tisch": {
      title: 'Aquarium (Tisch)',
      x: 530,
      y: 90,
      links: ['R0010446-aquarium'],
      "northOffset": 270,
    },
    "R0010446-aquarium": {
      title: 'Aquarium',
      x: 470,
      y: 90,
      links: ['R0010445-aquarium-tisch', 'R0010447-aq-treppe'],
      northOffset: 275
    },
    "R0010447-aq-treppe": {
      title: 'Treppe Aquarium',
      x: 460,
      y: 130,
      links: ['R0010446-aquarium', 'R0010448-salon-eingang'],
      northOffset: 230
    },
    "R0010448-salon-eingang": {
      title: 'Salon (Eingang)',
      x: 480,
      y: 190,
      links: ['R0010447-aq-treppe', 'R0010449-salon-leinwand'],
      northOffset: 230
    },
    "R0010449-salon-leinwand": {
      title: 'Salon (Leinwand)',
      x: 500,
      y: 280,
      links: ['R0010448-salon-eingang', 'R0010450-salon-datenschrank'],
      northOffset: 260
    },
    "R0010450-salon-datenschrank": {
      title: 'Salon (Datenschrank)',
      x: 380,
      y: 320,
      links: ['R0010449-salon-leinwand', 'R0010451-gang-1'],
      northOffset: 230
    },
    "R0010451-gang-1": {
      title: 'Gang 1',
      x: 370,
      y: 380,
      links: ['R0010450-salon-datenschrank', 'R0010452-gang-2'],
      northOffset: 255
    },
    "R0010452-gang-2": {
      title: 'Gang 2',
      x: 370,
      y: 440,
      links: ['R0010451-gang-1', 'R0010453'],
      northOffset: 230
    },
    "R0010453": {
      x: 380,
      y: 525,
      links: ['R0010452-gang-2', 'R0010454', 'R0010478-hase', 'R0010459'],
      northOffset: 245
    },
    "R0010454": {
      x: 405,
      y: 610,
      links: ['R0010453', 'R0010455-baer', 'R0010457-katze', 'R0010458-laserlab', 'R0010459'],
      northOffset: 190
    },
    "R0010455-baer": {
      title: 'Bär',
      x: 485,
      y: 580,
      links: ['R0010454'],
      northOffset: 280
    },
    "R0010457-katze": {
      title: 'Katze',
      x: 480,
      y: 690,
      links: ['R0010454'],
      northOffset: 130
    },
    "R0010458-laserlab": {
      title: 'Laserlab',
      x: 390,
      y: 690,
      links: ['R0010454'],
      northOffset: 10
    },
    "R0010459": {
      x: 280,
      y: 590,
      links: ['R0010454', 'R0010453'],
      northOffset: 305
    },
    "R0010478-hase": {
      title: 'Hase',
      x: 405,
      y: 420,
      links: ['R0010453'],
      northOffset: 230
    }
  }
};

let activeScene = null;
const mapMarkers = {};
const map = SVG('minimap').size(400, 400);
map.polyline(config.map.outline)
  .fill('none')
  .stroke({ color: '#fff', width: 5 })
  .translate(-150, -230)
  .scale(0.5, 0.5);

Object.keys(config.scenes).forEach(s => {
  config.scenes[s].cubeMap = [
    `/demo/${s}/pano_f.jpg`,
    `/demo/${s}/pano_r.jpg`,
    `/demo/${s}/pano_b.jpg`,
    `/demo/${s}/pano_l.jpg`,
    `/demo/${s}/pano_u.jpg`,
    `/demo/${s}/pano_d.jpg`
  ];

  if (Array.isArray(config.scenes[s].links)) {
    config.scenes[s].hotSpots = [];
    config.scenes[s].links.forEach(l => {
      const theta = Math.atan2(
        config.scenes[s].x - config.scenes[l].x,
        config.scenes[s].y - config.scenes[l].y
      ) * -180 / Math.PI - config.scenes[s].northOffset;

      config.scenes[s].hotSpots.push({
        pitch: -15,
        yaw: theta,
        type: 'scene',
        text: config.scenes[l].title,
        sceneId: l
      });
    });
  }

  mapMarkers[s] = map.image(cameraIcon)
    .translate(config.scenes[s].x/2 - 10, config.scenes[s].y/2 - 10)
    .scale(.07, .07)
    .click(() => viewer.loadScene(s))
    .mouseout(function() { this.animate(150).scale(.07, .07); })
    .mouseover(function() { this.animate(150).scale(.09, .09); });
});

const viewer = window.pannellum.viewer('panorama', config);
viewer.on('load', () => {
  if (activeScene !== null) {
    mapMarkers[activeScene].load(cameraIcon);
  }
  activeScene = viewer.getScene();
  mapMarkers[activeScene].load(activeCameraIcon);
});